import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Button } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import "../styles/404.css"

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "logo_peugeot_404_not_found.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      className="background-404"
      fluid={data.background.childImageSharp.fluid}
    >
      <div className="box-404">
        <h1 className="title-404">Oups !</h1>
        <p className="paragraphe-404">
          La page que vous recherchez semble introuvable.
        </p>
        <Button variant="outline-dark">
          <Link className="link-404" to="/">
            revenir sur la page d'acceuil
          </Link>
        </Button>
      </div>
    </BackgroundImage>
  )
}

export default NotFound
